@import "../../assets/scss/utilities/variables";

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  &>img {
    width: 90%;
  }
}

.logo-login {
  text-align: center;

  &>img {
    width: 80%;
  }
}

.sidebar_toggle {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 0;
  cursor: pointer;
}