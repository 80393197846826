.content {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  margin: 0;
  padding: 0;
  object-fit: cover;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    min-height: 100vh;
    height: fit-content;
  }

  &.left-side {
    font-family: "Pangram Regular", sans-serif;
    align-self: start;
    background: #b6f4eb;
    background: -moz-linear-gradient(315deg, #a9efe5 100%, #cdfcf6 100%);
    background: -webkit-linear-gradient(315deg, #a9efe5 100%, #cdfcf6 100%);
    background: linear-gradient(315deg, #a9efe5 100%, #cdfcf6 100%);
    object-fit: cover;
    height: 100vh;
    min-height: 80rem;
    background: transparent radial-gradient(closest-side at 50% 50%, #D3FFF9 0%, #A9EFE5 100%) 0% 0% no-repeat padding-box;

    @media screen and (max-width: 1200px) {
      min-height: 100vh;
      height: unset;
      align-items: center;
      justify-content: center;
    }

    &.left-side-reversed {
      @media screen and (max-width: 1200px) {
        flex-direction: column-reverse !important;
      }
    }

    .right-side-content {
      flex: 0.5;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media screen and (max-width: 1200px) {
        flex: unset;
      }

      @media screen and (max-width: 1300px) {
        flex: 0.4;
      }

      p {
        font-size: 22px;
        margin-top: 3rem;
        font-family: "Pangram Regular", sans-serif !important;


        @media screen and (max-width: 1500px) {
          font-size: 19px;
        }


        @media screen and (max-width: 1200px) {
          display: none;

        }
      }
    }


  }

  &.right-side {
    align-self: start;
    background: #fffb88;
    background: -moz-linear-gradient(315deg, #fffba0 0%, #fffdc9 100%);
    background: -webkit-linear-gradient(315deg, #fffba0 0, #fffdc9 100%);
    background: linear-gradient(315deg, #fffba0 100%, #fffdc9 100%);
    height: 100vh;
    min-height: 80rem;
    background: transparent radial-gradient(closest-side at 50% 50%, #FFFDD3 0%, #FFFA7D 100%) 0% 0% no-repeat padding-box;

    @media screen and (max-width: 1200px) {
      height: unset;
      min-height: 100vh;
      align-items: center;
      justify-content: center;
    }

    .right-side-content {
      flex: 0.5;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media screen and (max-width: 1200px) {
        flex: unset;
      }

      p {
        font-size: 22px;
        margin-top: 3rem;
        font-family: "Pangram Regular", sans-serif;

        @media screen and (max-width: 1500px) {
          font-size: 19px;
        }

        @media screen and (max-width: 1200px) {
          display: none;
          flex: 0;
        }
      }

      @media screen and (max-width: 1200px) {
        flex-direction: column;
      }
    }
  }

  .form-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 1200px) {
      position: relative;
      width: 70%;
    }
  }

  .promo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .promotion {
    position: relative;
    background-image: url("../../assets//img/ellipse.svg");
    background-repeat: no-repeat;
    width: 21rem;
    height: 15rem;
    margin-top: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: "Pangram", sans-serif;
    font-size: 11px;
    font-weight: bold;
    line-height: 12px;
    text-align: center;
    padding-bottom: 10px;

    &::after {
      content: "";
      position: absolute;
      top: 47%;
      left: 49%;
      transform: translate(-50%, -50%);
      width: 115px;
      height: 115px;
      border-radius: 50%;
      border: 4px solid #FFF500;

      @media screen and (max-width: 1350px) {
        top: 52%;
        left: 47%;
      }
    }

    @media screen and (max-width: 1200px) {
      padding-bottom: 0px;
    }
  }

  .title {
    font-family: "Pangram Regular", sans-serif;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    color: black;
    margin-top: 20%;
    margin-right: 15%;

    @media screen and (max-width: 800px) {
      font-size: 20px;
    }

    @media screen and (min-width: 1600px) {
      margin-top: 25%;
    }

    @media screen and (max-width: 1350px) {
      margin-right: 10%;
    }
  }

  .description {
    line-height: 53px;
    color: black;
    font-family: "Pangram Regular", sans-serif !important;
    font-size: 2rem;
    margin-top: 1rem;

    @media screen and (max-width: 800px) {
      font-size: 15px;
    }
  }

  .auth-button-wrapper {
    text-align: right;
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 20px;

    .auth-button-radio {
      display: flex;
      align-items: center;
      gap: 8px;

      input[type="radio"] {
        font-size: 3em;
        width: 23px;
        height: 23px;
        margin-right: 7px;
        border: 2px solid #707070;
        background-clip: border-box;
        border-radius: 50%;
        appearance: none;
        transition:
          background-color 0.3s,
          box-shadow 0.3s;
      }

      input[type="radio"]:checked {
        box-shadow: 0px 0px 0px 1px #707070;
        background-color: #707070;
        border-color: #b9eee5;
      }

      label {
        font-size: 14px;
        font-family: 'Pangram Regular', Arial, sans-serif !important;
      }
    }

    .auth-button {
      margin-right: 5%;
      align-items: center;
      font-weight: bold;
      font-size: 1.6rem;
    }
  }

  .reset-link-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 45rem;

    .reset-link {
      text-align: right;
      text-decoration: underline;
      padding-top: 1rem;
      padding-right: 3rem;
      color: black;
      font-family: "Pangram Regular", sans-serif !important;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
    }
  }

  .sub-container {
    position: absolute;
    width: 50%;
    min-width: 75rem;
    border-radius: 0 50% 50% 0;

    &.left {
      left: 0;

      .circle {
        margin: 9rem 9rem 9rem 2rem;
      }
    }

    &.unset-bg {
      background-image: none;
    }

    &.right {
      width: 49%;
      right: 0;
      border-radius: 50% 0 0 50%;
    }

    &.blue {
      background-image: radial-gradient(circle,
          transparent 1%,
          darken(#a9efe5, 1) 1%);

      @media screen and (max-width: 1200px) {
        background-image: unset;
      }
    }

    &.yellow {
      background-image: radial-gradient(circle,
          transparent 1%,
          darken(#fffa7d, 1) 1%);

      @media screen and (max-width: 1200px) {
        background-image: unset;
      }
    }

    @media screen and (max-width: 1200px) {
      position: relative;
    }
  }

  .circle {
    flex-direction: column;
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 12rem 0;
    margin: 9rem 2rem 9rem 9rem;



    h3 {
      font-family: "Pan", sans-serif;
      font-size: 5.3rem;
    }

    p {
      font-family: "Pangram Regular", sans-serif !important;
      font-size: 2rem;
      margin-top: 1rem;
      @media screen and (max-width: 1500px) {
        font-size: 19px;
      }
    }

    form {
      margin-top: 4rem;
    }

    .submit-button {
      text-align: right;
      margin-top: 2rem;
      font-family: "Pangram Regular" , sans-serif !important;

      @media screen and (max-width: 1200px) {
        text-align: center;
      }
    }

    @media screen and (max-width: 1200px) {
      all: unset;
    }

    &.blue {
      background-color: #a9efe5;
    }

    &.yellow {
      background-color: #fffa7d;
    }

    @media screen and (max-width: 1200px) {
      position: relative;
    }

    .reversed-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      transform: rotate(-180deg);
    }
  }

  .login-divider {
    border-top: 3px solid #707070;
    margin: 12px 0;
  }

  .register-footer {
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50%;
    font-family: "Pangram Regular", sans-serif !important;

    @media screen and (max-width: 1200px) {
      width: 80%;
      height: fit-content;
      margin-top: 5%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      height: fit-content;
      margin-top: 5%;
    }
  }

  .text-footer {
    font-size: 15px;
    font-weight: normal;
    line-height: 17px;
    font-style: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Pangram Regular", sans-serif !important;

    @media screen and (min-width: 1600px) {
      font-size: 15px;
      width: 85%;
      margin: 0 auto;
    }
  }

  .reset-password {
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: black;
    text-align: right;
    width: 100%;
    display: flex;
    justify-content: center;

    &>a {
      margin-left: 2%;
      color: black;
      text-decoration: underline;
    }

  }

  label {
    font-family: "Pangram Regular", sans-serif !important;
  }

  .login-footer {
    font-size: 1.7rem;
    color: black;
    text-align: right;
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 500;
    margin-top: 3rem;

    &>a {
      font-family: "Pangram Regular", sans-serif !important;

      margin-left: 7px;
      text-decoration: underline;
      color: black;
    }

    @media screen and (max-width: 1200px) {
      margin-top: 5%;
    }
  }

  .connection-button {
    display: flex;
    justify-content: end;
  }
}

.form-forget-password {
  max-width: 44rem;
  margin: 0 auto;
}

.square-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 44rem;
  width: 55rem;


  @media screen and (max-width: 1200px) {
    height: unset;
    width: unset;
    margin-top: 5rem;
  }

  h3 {
    white-space: nowrap;
  }

  p {
    white-space: nowrap;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
}




.logo-login {
  @media screen and (max-width: 1200px) {
    width: 60%;
  }
}