.submitting-button {
  outline: none;
  border: none;
  color: #fff;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  border-radius: 24px;
  background: #000;
  height: 35px;
  width: 145px;
  cursor: pointer;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
}

.rigth-icon-wrapper {
  width: 61px;
  height: 26px;
}