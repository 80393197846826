.confirm-delete-buttons {
  display: flex;
  justify-content: center;
  gap: 14%;
  margin-bottom: 3%;

  .delete-button {
    width: 194px !important;
    height: 41px !important;
    font-size: 2.1rem !important;
    font-weight: bold !important;
  }
}