@import "../../assets/scss/utilities/variables";

.sidebar-wrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;

  span {
    color: $black;
    font-size: 1.2rem;
    font-weight: bold;
  }

  &>.sidebar_toggle {

    bottom: 11px;
    left: 50%;
    position: absolute;
    background-color: #ebebeb;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    z-index: 7;
    border-radius: 0 55px 55px 0;
    height: 30px;
    gap: 10px;

    img {
      filter: invert(72%) sepia(54%) saturate(0%) hue-rotate(276deg) brightness(95%) contrast(105%);
    }

    &.blue {
      background-color: #a9efe5;
    }

    &.yellow {
      background-color: #fffa7d;
    }
  }

  #arrow {
    @media only screen and (max-width: 900px) {
      transform: rotate(90deg);
      transition: transform 0.3s ease;
    }
  }

  &>.sidebar {
    position: relative;
    height: 100vh;
    min-height: 100vh;
    padding: 0px 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-right: 1px solid transparent;
    transition: ease 0.1s;
    background: $pure_gray;
    box-shadow: 0px 0px 10px 0px $transparentBlack;
    gap: 2%;
    z-index: 7;
    max-width: 259px;

    .hidden-element {
      text-align: left;
    }

    @media only screen and (max-width: 900px) {
      align-items: center !important;
    }

    &.blue {
      background-color: #a9efe5;
    }

    &.yellow {
      background-color: #fffa7d;
    }

    &>.sidebar_header {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      cursor: pointer;
      padding-bottom: 94px;

      @media only screen and (max-width: 900px) {
        margin-bottom: 30px;
      }
    }

    nav {
      width: 90%;
    }
  }

  @media only screen and (max-width: 900px) {
    .sidebar {
      width: 100px;

      .hidden-element {
        display: none;
      }
    }
  }

  .ant-picker-range.ant-picker-focused .ant-picker-active-bar {
    opacity: 0;
  }

  .mode-button,
  .button {
    height: 40px;
    border-radius: 40px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 2% 14.75px;
    display: flex;
    align-items: center;
    text-align: center;
    background: #fff;
    width: 230px;
    gap: 11px;
    cursor: pointer;

    @media only screen and (max-width: 900px) {
      justify-content: center;
    }

    &:hover {
      text-shadow: 0px 0px 0.5px $guyabano;
      background: $guyabano;
    }

    &.disabled {
      opacity: 0.7;
      background-color: $lightGreen;
    }

    &.border-category {
      border-right: 1px solid $lighter_gray;

      @media only screen and (max-width: 900px) {
        width: 100%;
      }
    }

    &.nav-link {
      width: 227px;
      gap: 11px;
      border: none;

      @media only screen and (max-width: 900px) {
        width: 100%;
      }

      img {
        height: 30px;
      }

      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        background: $white;

        &.disabled {
          box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.16);
          background: #ffffff;
        }

        @media only screen and (max-width: 900px) {
          gap: 0px;
        }

        span {
          color: $black;
          font-weight: bold;
        }
      }

      &:nth-child(8),
      &:nth-child(9) {
        background: $white;

        @media only screen and (max-width: 900px) {
          gap: 0px;
        }

        span {
          color: $black;
          font-weight: bold;
        }

        &.nav-img {
          margin-right: 14px;

          @media only screen and (max-width: 900px) {
            margin-right: 0;
          }
        }
      }
    }

    &.config-buttons {
      border-radius: 24px;
      border: 1px solid transparent;
      box-shadow: none;

      &:hover {
        text-shadow: 0px 0px 0.5px $guyabano;
        background: $guyabano;
      }

      @media only screen and (max-width: 900px) {
        gap: 0;
      }
    }

    /*******Switch*********/

    .button-group {
      display: flex;
      flex-direction: row;
      padding-left: 0;

      & .switch-text {
        font-size: 10px;
        align-items: center;
        font-weight: bold;
      }

      & .treso-text {
        margin-top: 5px;
        margin-left: 5px;
      }

      .switch-icon {
        width: 15px;

        &.tresory {
          margin-left: 5px;
          margin-top: 5px;
        }

        &.compta {
          margin-left: 5px;
        }
      }

      .comptability-container {
        margin-left: 5px;
      }

      .switch-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 88%;

        &.unchecked-icon {
          padding-right: 35px;
        }

        &.checked-icon {
          padding-left: 35px;
          padding-top: 6px;
        }

        &.unchecked-handle-icon {
          font-size: 20px;
          border: 4px solid #fffa7d;
          margin: 6%;
          border-radius: 50px;
        }

        &.checked-handle-icon {
          font-size: 18px;
          border: 4px solid #a9efe5;
          border-radius: 50px;
          margin: 6%;
        }
      }

      .comptability-container {
        margin-right: 5px;
      }



      .react-switch.compta {
        .react-switch-bg {
          box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.16);
          display: flex;
          justify-content: space-between;
          background: linear-gradient(-90deg,
              #ffffff 0%,
              #a9efe5 100%) !important;
        }
      }

      .react-switch.treso {
        .react-switch-bg {
          box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.16);
          display: flex;
          justify-content: space-between;
          background: linear-gradient(90deg,
              #ffffff 0%,
              #fffa7d 100%) !important;
        }
      }
    }
  }

  /****Divider****/

  .divider {
    width: 178px !important;
    height: 0px;
    margin: 2% 13px 2% 20px;
    // @media only screen and (max-height: 650px) {
    //   margin: 13px ;
    // }

    @media only screen and (max-width: 900px) {
      width: 52px !important;
      margin-left: 7px !important;
    }
  }

  .treso-divider {
    border: 0.5px solid #f0f0f0;
  }

  .compta-divider {
    border: 0.5px solid #d0fdf7;

    @media only screen and (max-width: 900px) {
      border: 0.5px solid #e5e064;
    }
  }

  /****Vue****/

  // .text-container {
  //   position: relative;
  //   width: 120px;
  //   height: 40px;
  //   border-top-left-radius: 116px;
  //   border-left: 1px solid $lighter_gray;
  //   border-top: 1px solid $lighter_gray;
  //   border-bottom: 1px solid $lighter_gray;
  //   border-bottom-left-radius: 116px;
  //   margin-left: 20px;

  //   .hidden-element-text {
  //     position: absolute;
  //     font-weight: bold;
  //     font-size: 1.3rem;

  //     top: 10px;
  //     left: 10px;
  //   }
  // }
}

/*********Range picker*********/

.input-label-range-picker {
  margin-left: 15px;
  margin-top: 3px;
  display: flex;
  gap: 30px;
  align-items: center;

  .input-label-synthese {
    padding: 8px 10px;
    width: 100px;
    position: relative;
    top: -2px;
    border-color: #eaeaea !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-radius: 53px !important;
    box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.16) !important;
    font-size: 11px;
    white-space: nowrap;
  }
}

/*********settings*********/

.setting-wrapper {
  width: 100%;

  .element-centred {
    justify-content: unset;
  }

  .margin-element {
    margin-right: 8px;
    cursor: pointer;
  }

  .select-form {
    cursor: pointer;
    margin-bottom: 22px;
    width: 100%;
    height: 40px;

    span {
      display: block;
    }
  }

  .logout {
    @media only screen and (max-width: 900px) {
      justify-content: space-between;
    }
  }
}

/******* subnavigation  *********/

.sub-nav {
  z-index: 2000;
  position: absolute;
  top: 12rem;
  left: 8.5rem;
}

.sub-nav-inner {
  gap: 29px;

  nav {
    margin-bottom: 12px;
  }
}

.button-types {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin: 2.5rem 1.2rem;
  border-radius: 40px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
  padding: 2px 10px;
  font-weight: 600;

  img {
    width: 30px;
  }

  .nav-link {
    width: 160px !important;
  }

  &.popover-button {
    background-color: red;
  }
}

/******* overlay  *********/

.ant-popover-inner-content {
  .button {
    height: 40px;
    border-radius: 16px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 7px 14.75px;
    display: flex;
    align-items: center;
    text-align: center;
    background: #fff;
    width: 150px;
    cursor: pointer;
    background-color: red;

    &:hover {
      text-shadow: 0px 0px 0.5px $guyabano;
      background: $guyabano;
    }

    &.disabled {
      opacity: 0.9;
      background-color: $lightGreen;
    }

    &.nav-link {
      width: 227px;
      gap: 12px;
      border: none;
      background: $white;

      &:hover {
        text-shadow: 0px 0px 0.5px $guyabano;
        background: $guyabano;
      }

      span {
        color: $dark_gray;
        opacity: 0.8;
        font-weight: bold;
      }

      &.nav-img {
        margin-right: 14px;
      }
    }
  }
}

.nav-img:not(#arrow) {
  height: 30px;
  width: 30px;
}

.config-buttons {
  .nav-img:not(#arrow) {
    height: 25px;
    width: 25px;
    margin-right: 5px;
  }
}

.sidebar-closed {
  .config-buttons {
    .nav-img:not(#arrow) {
      margin-right: 0px;
    }
  }
}


.button-menu {
  position: relative;

  &:hover {
    color: black !important;
  }


  .nav-img-chevron {
    transform: rotate(-90deg);
    filter: brightness(0%);
    width: 11px;
    height: 15px;
  }

  .text-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 100%;

    .hidden-element-text {
      font-weight: bold;
      font-size: 1.3rem;
    }
  }
}

.button-switch-container {

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .button-switch-text {
    width: 53px;
  }
}