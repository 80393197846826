.element-cell-wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  text-align: center;
  position: relative;
  border: 2px solid transparent;

  &.hovered-cell-with-compta-element {
    border: 2px solid #aaefe6;
  }

  // &.cell-special-with-treso-element {
  //   border: 2px solid rgb(124 124 124);
  // }

  // &.cell-with-special-compta-element {
  //   border: 2px solid rgb(124 124 124);
  // }

  &.hovered-cell-with-treso-element {
    border: 2px solid rgb(233, 233, 0);
  }

  &.cell-with-solded-compta-element {
    background: #aaefe6e6;
  }

  .element-cell-left {
    margin-right: 3px;
    word-wrap: break-word;
    white-space: normal;
    text-align: left;
    line-height: normal;
    max-height: 3.4em;
    overflow: hidden;
    font-size: 1.3rem;
  }

  .element-cell-rigth {
    white-space: nowrap;
    text-align: right;

    .element-centred {
      justify-content: flex-end;
      gap: 1rem;
      align-items: stretch;

      img {
        width: 1.5rem;
      }

      .duplicated-icon {
        filter: invert(72%) sepia(54%) saturate(0%) hue-rotate(276deg) brightness(95%) contrast(105%);
      }
    }

    span {
      &.status {
        color: #a8a8a8;
        opacity: 0.8;
        font-size: 1rem;
      }

      font-size: 1.3rem;
    }

    .dash-small {
      font-size: 15px;
      margin-right: 0.5rem;
    }

    .dash {
      font-size: 19px;
      font-weight: bold;
      margin-right: 1rem;

      &.dash-positive {
        color: #729c72;
      }

      &.dash-negative {
        color: #fa5450;
      }
    }
  }
}

.draggable-element {
  border: 0.5px solid rgb(235, 235, 235);
}

.pointed-class {
  background-color: #fffa7d;
}

.engaged-class {
  background-color: #fffdbf;
}

.prev-class {
  background-color: #ffffd9;
}


.default-class {
  background-color: #ffffff;
}

.real-class {
  background-color: #e6fbf8;
}

.dragged-element {
  width: 175px !important;
  padding: 4px;
  border: 2px dashed rgb(65, 65, 65) !important;
  opacity: 0.7;
}

.hoveredSum {
  background-color: red !important;
}

.removed {
  opacity: 0.4;
}