.project-container {
  &>.new-project-big-container {
    .project-form-wrapper {
      gap: 27px;

      .error-displayer {
        color: red;
      }

      .project-form {
        .project-helper {
          margin-bottom: 26px;

          span {
            font-size: 1.5rem;
            display: block;
            margin-top: 2rem;
            font-weight: 600;
            color: #989f9e;
          }
        }

        .custom-input label {
          font-size: 2.2rem;
          font-weight: 400;
          font-style: italic;
        }

        .input-wrapper {
          justify-content: flex-start;

          input::placeholder {
            font-style: italic;
          }

          .name-input {
            width: 100%;
            margin-bottom: 0;
          }

          .email-input {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .project-add-visitor {
    display: flex;
    margin: 5rem 0;
    text-align: center;
    align-items: center;
    gap: 3%;
    justify-content: flex-end;
    margin-right: 59%;

    @media screen and (max-width: 550px) {
      margin: 2.5rem 0;
    }

    .btn-primary {
      font-size: 2rem;
      height: 41px;
      width: 194px;
    }
  }

  .budget-btn {
    width: 635px;
    margin-right: unset;
  }

  .budget-footer {
    margin: 5rem 389px;
    margin-bottom: 13%;
  }
}