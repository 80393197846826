// Primary color
$primary-color: #0072c6;

// Generate shades of primary color
@function shade($color, $percentage) {
  @return mix(white, $color, $percentage);
}

$primary-color-100: $primary-color;
$primary-color-90: shade($primary-color, 10%);
$primary-color-80: shade($primary-color, 20%);
$primary-color-70: shade($primary-color, 30%);
$primary-color-60: shade($primary-color, 40%);
$primary-color-50: shade($primary-color, 50%);
$primary-color-40: shade($primary-color, 60%);
$primary-color-30: shade($primary-color, 70%);
$primary-color-20: shade($primary-color, 80%);
$primary-color-10: shade($primary-color, 90%);

$secondary-color: #00b388;

$secondary-color-100: $secondary-color;
$secondary-color-90: shade($secondary-color, 10%);
$secondary-color-80: shade($secondary-color, 20%);
$secondary-color-70: shade($secondary-color, 30%);
$secondary-color-60: shade($secondary-color, 40%);
$secondary-color-50: shade($secondary-color, 50%);
$secondary-color-40: shade($secondary-color, 60%);
$secondary-color-30: shade($secondary-color, 70%);
$secondary-color-20: shade($secondary-color, 80%);
$secondary-color-10: shade($secondary-color, 90%);

$grey-border: #e8e8e8;
$guyabano: #F8F8F8;
$aliceBlue: #F0FCFA;

$black: #000;
$white: #fff;
$background-gray: #fbfbfb;
$blue: blue;
$pure_white: white;
$darker_white: white;
$darker_blue: blue;
$transparentBlack: rgba(0, 0, 0, 0.25);
$lightGray: rgb(183, 180, 180);
$gray: #979797;
$pure_gray: #fbfafa;
$lighter_gray: #E9E9E9;
$lightBlue: #aaefe6;
$purple: #c9aaef; 
$white: #FFFFFF;
$yellow: #FFFA7D;
$lightGreen: #F0FEEB;
$lightRed: #FEEBEB;
$dark_gray: #6f6f6f;
$black-color-100: shade($black, 10%);
$black-color-90: shade($black, 10%);
$black-color-80: shade($black, 20%);
$black-color-70: shade($black, 30%);
$black-color-60: shade($black, 40%);
$black-color-50: shade($black, 50%);
$black-color-40: shade($black, 60%);
$black-color-30: shade($black, 70%);
$black-color-20: shade($black, 80%);
$black-color-10: shade($black, 90%);
$black-color-5: shade($black, 95%);
$black-color-2: shade($black, 98%);

.red {
  color: red;
  margin-left: 2px;
}
@mixin scroll {
  &::-webkit-scrollbar {
    height: 0.6rem;
    width: 0.6rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.4rem;
    background-color: $primary-color-70;

    &:hover {
      background-color: $primary-color-90;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.4rem;
    background-color: white;
    border: 1px solid #cacaca;
  }
}
