.add-wrapper {
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: 4rem;

  .element-centred {
    cursor: pointer;
  }

  .text-add-wrapper {
    font-size: 2rem;
    font-weight: bold;
    margin: 0 33px 0 16px;
  }
}