.graph {
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 100vw;
  height: 100vh;
  align-items: center;
  overflow: hidden;
  margin-left: 1%;
  padding: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  .chart-buttons {
    top: 0%;
    position: fixed;
    left: 50%;
    transform: translateY(calc(50%));
    font-weight: bold;

    @media only screen and (max-width: 1293px) {
      left: 33%;
    }

    @media only screen and (max-width: 1024px) {
      left: 25%;
      top: 4%;
    }

    .arrow {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid transparent;
      top: -19px;
      border-bottom: 10px solid rgb(255, 255, 255);
      position: absolute;
      // right: 250px;
    }

    .compta-style {
      left: 162%;

      .arrow {
        right: 48%;
      }
    }

    .treso-style {
      left: 10%;

      .arrow {
        right: 58%;
      }
    }

    .element-treso-style {
      left: 1%;
    }

    .element-compta-style {
      left: 148%;
      position: absolute;
    }
  }

  .chart-elements-types {
    display: flex;
    gap: 4px;
    min-width: 28.2rem;
    margin-top: 1.2rem;
    border-radius: 40px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    padding: 6px 10px;
    font-weight: 600;
    font-size: 11px;

    &>div {
      white-space: nowrap;

      &.chart-small-signature {
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    gap: 2px;
    align-items: stretch;
    flex: 1;
  }

  .elements-styled {
    position: relative;
  }

  .popover-filter-table {
    position: fixed;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    background: #ffffff;
    padding: 40px 5px 12px 12px;
    border-radius: 20px;
    text-align: center;

    .ant-radio-wrapper {
      span:nth-of-type(2) {
        flex: 1;
        text-align: left;
      }
    }

    .change-type {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .checkbox-filter {
        margin-bottom: 20px;

        .ant-radio-checked {
          .ant-radio-inner {
            background-color: white;
            border: 1px solid grey;

            &::after {
              background-color: grey;
              width: 28px;
              height: 28px;
              margin-block-start: -14px;
              margin-inline-start: -14px;
            }
          }
        }

        &>span:not(.ant-radio .ant-wave-target) {
          display: block;
        }

        .elements-types {
          padding: 4px 10px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          text-align: center;

          &>div {
            white-space: nowrap;

            &.small-signature {
              font-size: 11px;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 20px;
            }
          }

          gap: 2px;
          align-items: stretch;
          flex: 1;
        }
      }
    }
  }
}