.custom-input {
  display: flex;
  align-items: start;
  flex-direction: column;
  max-width: 45rem;
  margin-bottom: 0.7rem;

  .input-wrapper {
    display: flex;
    gap: 2rem;
    align-items: center;



    // justify-content: center;
    input {
      padding-left: 5rem;
      font-size: 1.5rem;

      &::placeholder {
        font-weight: 400;
        font-size: 1.8rem;
        color: rgba(111, 111, 111, 0.87);
      }

      &.input-wrapper-writing {
        background-color: red;
        padding-left: 1rem;

        &::placeholder {
          font-weight: unset;
          font-size: unset;
          color: rgb(200, 200, 200);
        }
      }

      .input-wrapper-project {
        padding-left: 1rem;

        &::placeholder {
          font-weight: unset;
          font-size: unset;
          color: rgb(200, 200, 200);
        }
      }
    }

    .auth-input {
      input {
        padding-left: 4rem;
        font-size: 1.5rem;
      }
    }
  }

  .password-validation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-left: auto;
    margin-top: 1rem;

    .password-validation-item {
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      p {
        margin-top: 0;
        font-size: 1rem;
      }
    }
  }

  label {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .error-displayer {
    font-size: 1.3rem;
    font-weight: normal;
    color: red;
    width: 90%;
    margin: 0.4rem auto 0 auto;
    text-align: center;
  }
}

.ant-input {
  background-color: transparent !important;
}

.auth-input {
  border: 2px solid #000 !important;
  border-radius: 40px !important;
  display: flex !important;
  width: 43rem !important;

  &.large {
    height: 5.4rem !important;
    background-color: transparent;
  }
}

// .project-input{
//   width:65rem !important;
// }

.form-element-input,
.ant-input-affix-wrapper {
  border: 2px solid #000000 !important;
  border-radius: 40px !important;
  display: flex;
  height: 3.8rem !important;
  background-color: white;

  &.white {
    background-color: white;
  }

  &.large {
    height: 5.4rem !important;
    background-color: transparent;
  }

  &.small {
    height: 3.8rem !important;
    background-color: transparent;
    width: 100% !important;
  }


  &.calendar {
    height: 3.8rem;
    width: 22.4rem !important;



    .ant-picker-panel {
      background-color: red;
    }

    .ant-picker-dropdown .ant-picker-header {
      padding: 0 63px;
    }
  }

  &.not-filled {
    width: 100% !important;
    background-color: white !important;
  }

  &>img {
    margin-left: -4rem;
  }

  input {
    background-color: transparent;
  }
}

.disabled-input {
  color: black !important;
  font-style: italic;
}

.projectName {
  font-style: italic;
}

.square-content {
  .custom-input {
    margin-bottom: 1.5rem;
  }
}