.project-card {
  margin-top: 1rem;
  max-height: 40rem;
  overflow-y: auto;
  padding-right: 7px;
}

.add-component-margin {
  margin-bottom: 10%;
}

.project-card-contact {
  display: flex;
  text-align: center;
  align-items: center;
  // margin-left: 17%;
  justify-content: center;
  gap: 3%;
  margin-top: 30px;


  span {
    font-size: 2rem;
    white-space: nowrap;

    @media screen and (max-width: 1039px) {
      font-size: 1.5rem;
    }
  }

  &>.promotion {
    background-image: url("../../../assets/img/ellipse.svg");
    background-repeat: no-repeat;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: RGBA(111, 111, 111, 0.87);
    font-family: "Pangram", sans-serif;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    transform: rotate(-16deg);

    .promotion-centred-element {
      border: 2px solid #fff500;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.project-input {
  border: 2px solid #000 !important;
  border-radius: 40px !important;
  display: flex !important;
  width: 635px !important;

  &.large {
    height: 5.4rem !important;
    background-color: transparent;
  }
}

.project-input::placeholder {
  font-size: 24px;
  font-weight: 600 !important;
  line-height: 68px;
  font-style: normal;
  text-align: left;
  color: rgba(111, 111, 111, 0.87);
}

.budget-input {
  border: 2px solid #000 !important;
  border-radius: 40px !important;
  display: flex !important;
  width: 635px !important;

  &.large {
    height: 5.4rem !important;
    background-color: transparent;
  }
}

.budget-input::placeholder {
  font-size: 24px;
  font-weight: 600 !important;
  line-height: 68px;
  font-style: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.87) !important;
}