@font-face {
  font-family: 'Pangram ExtraLight';
  src: local('Pangram ExtraLight'), url('../../fonts/Pangram-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Pangram Medium';
  src: local('Pangram Medium'), url('../../fonts/Pangram-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Pangram Regular';
  src: local('Pangram Regular'), url('../../fonts/Pangram-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pangram Light';
  src: local('Pangram Light'), url('../../fonts/Pangram-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Pangram Bold';
  src: local('Pangram Bold'), url('../../fonts/Pangram-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Pangram Black';
  src: local('Pangram Black'), url('../../fonts/Pangram-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Pangram ExtraBold';
  src: local('Pangram ExtraBold'), url('../../fonts/Pangram-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}