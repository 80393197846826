.project-title {
  margin-bottom: 24px;

  &.objective-text {
    margin: 2rem;
    margin-bottom: 0;

    span {
      font-size: 1.7rem !important;

    }
  }

  h4 {
    font-size: 3rem;

    &.delete-h4 {
      text-align: center;
    }
  }


  span {
    font-size: 2rem !important;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.87);

  }



  .delete-text {
    text-align: center;
    margin: 3rem 0;
    font-size: 20px;
  }

  #confirm-delete {
    font-size: 25px !important;
    color: #fc7d7d;
    font-weight: bold;
  }

  .element-centred {
    margin-top: 2rem;
    justify-content: center;
  }
}




.objective-wrapper {
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: -10%;
  gap: 1%;

  @media screen and (max-width: 1480px) {
    margin-left: -2%;
  }
}