#input-icon {
  height: 32px !important;
  width: 32px !important;
}

#input-label-synthese {
  color: black !important;
}

.custom-RangePicker-dropdown {
  .ant-picker-header > * {
    flex: inherit;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .ant-picker-header {
    border-bottom: unset !important;
  }

  .ant-picker-header > button {
    &.ant-picker-header-super-prev-btn {
      padding-left: 20px !important;
      padding-top: 4px;
    }

    &.ant-picker-header-super-next-btn {
      padding-right: 54px !important;
    }

    color: black !important;
    font-weight: bold !important;
    min-width: 9rem !important;
    background-color: red;

    svg {
      margin-top: 12px;

      image {
        width: 45px;
        height: 45px;
      }
    }
  }

  .ant-picker-panels {
    background: #f0fcfa;
    border: 1px solid #161111;
    border-radius: 40px;
  }

  .ant-picker-panel-container {
    box-shadow: unset !important;
    background-color: unset !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    opacity: 0.49;
    background: #a9efe5;
  }
  .ant-picker-cell-selected {
    height: 43px !important;
  }

  .ant-picker-cell-in-view {
    height: 60px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: black !important;
    background: #a9efe5 !important;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    width: 50px;
    height: 50px;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 20%;
    top: 10%;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    ):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    ):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner {
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    border-radius: 50%;
    background: #a9efe5;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 1.8rem;
    font-weight: bold;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    )::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    )::before {
    opacity: 0.49;
    box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.16);
    background: #a9efe5;
  }

  .ant-picker-cell-inner {
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.custom-range-picker {
  > .ant-picker-range-separator {
    margin-left: -30px;
    padding: none;
  }

  > .ant-picker-separator {
    display: none !important;
  }

  > .ant-picker-suffix {
    height: 27px;
    align-items: center;
    margin-inline-start: 11px;
  }
}

.custom-range-picker.ant-picker .ant-picker-input {
  display: none;
}

.range-picker-button-wrapper {
  display: flex;
  align-items: center;
  text-align: center;

  span {
    @media only screen and (max-width: 900px) {
      display: none !important;
    }
  }
}

.ant-picker-year-btn {
  pointer-events: none;
}
